import {
  ANNOTATION_EDIT_URL,
  ANNOTATION_LOCATION,
  DEFAULT_NAMESPACE,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  HeaderIconLinkRow,
  IconLinkVerticalProps,
  Link,
} from '@backstage/core-components';
import {
  alertApiRef,
  errorApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import {
  ScmIntegrationIcon,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  catalogApiRef,
  getEntitySourceLocation,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import DocsIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import MonitoringIcon from '@material-ui/icons/TrackChanges'
import LoggingIcon from '@material-ui/icons/BlurLinear';
import JaegerIcon from '@material-ui/icons/ShowChart';
import React, { useCallback } from 'react';
  
import { viewTechDocRouteRef } from '../routes';
import { AboutCardProps } from '@backstage/plugin-catalog';
import { AboutContent } from './AboutContent';
  
const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
});
  
/**
 * Exported publicly via the EntityAboutCard
 */
export function AboutCard(props: AboutCardProps) {
  const { variant } = props;
  const classes = useStyles();
  const { entity } = useEntity();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const errorApi = useApi(errorApiRef);
  const viewTechdocLink = useRouteRef(viewTechDocRouteRef);

  const entitySourceLocation = getEntitySourceLocation(
    entity,
    scmIntegrationsApi,
  );
  const entityMetadataEditUrl =
    entity.metadata.annotations?.[ANNOTATION_EDIT_URL];
  let links: IconLinkVerticalProps[] = [];

  links.push({
    label: 'View Source',
    disabled: !entitySourceLocation,
    icon: <ScmIntegrationIcon type={entitySourceLocation?.integrationType} />,
    href: entitySourceLocation?.locationTargetUrl,
  });
  if (entity.metadata.annotations?.['99.co/techdoc-url']) {
    links.push({
      label: 'View TechDocs',
      disabled: !entity.metadata.annotations?.['99.co/techdoc-url'],
      icon: <DocsIcon />,
      href: entity.metadata.annotations?.['99.co/techdoc-url']
    });
  } else {
    links.push({
      label: 'View TechDocs',
      disabled:
        !entity.metadata.annotations?.['backstage.io/techdocs-ref'] ||
        !viewTechdocLink,
      icon: <DocsIcon />,
      href:
        viewTechdocLink &&
        viewTechdocLink({
          namespace: entity.metadata.namespace || DEFAULT_NAMESPACE,
          kind: entity.kind,
          name: entity.metadata.name,
        }),
    });
  }

  if (entity.metadata.annotations?.['99.co/monitoring-url']) {
    links.push({
      label: 'View Monitoring',
      icon: <MonitoringIcon />,
      href: entity.metadata.annotations?.['99.co/monitoring-url']
    });
  }
  if (entity.metadata.annotations?.['99.co/logging-url']) {
    links.push({
      label: 'View Logging',
      icon: <LoggingIcon />,
      href: entity.metadata.annotations?.['99.co/logging-url']
    });
  }
  if (entity.metadata.annotations?.['99.co/jaeger-url']) {
    links.push({
      label: 'View Jaeger',
      icon: <JaegerIcon />,
      href: entity.metadata.annotations?.['99.co/jaeger-url']
    });
  }

  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }

  const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];
  // Limiting the ability to manually refresh to the less expensive locations
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');
  const refreshEntity = useCallback(async () => {
    try {
      await catalogApi.refreshEntity(stringifyEntityRef(entity));
      alertApi.post({ message: 'Refresh scheduled', severity: 'info' });
    } catch (e) {
      console.error(e)
    }
  }, [catalogApi, alertApi, errorApi, entity]);

  return (
    <Card className={cardClass}>
      <CardHeader
        title="About"
        action={
          <>
            {allowRefresh && (
              <IconButton
                aria-label="Refresh"
                title="Schedule entity refresh"
                onClick={refreshEntity}
              >
                <CachedIcon />
              </IconButton>
            )}
            <IconButton
              component={Link}
              aria-label="Edit"
              disabled={!entityMetadataEditUrl}
              title="Edit Metadata"
              to={entityMetadataEditUrl ?? '#'}
            >
              <EditIcon />
            </IconButton>
          </>
        }
        subheader={<HeaderIconLinkRow links={links} />}
      />
      <Divider />
      <CardContent className={cardContentClass}>
        <AboutContent entity={entity} />
      </CardContent>
    </Card>
  );
}
