import { createComponentExtension, createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const graphiqlWidgetPlugin = createPlugin({
  id: 'graphiql-widget',
  routes: {
    root: rootRouteRef,
  },
});

export const GraphiQLWidget = graphiqlWidgetPlugin.provide(
  createComponentExtension({
    name: 'GraphiQLWidget',
    component: {
      lazy: () =>
        import('./components/GraphiQLComponent').then(m => m.GraphiQLWidget)
    }
  }),
);
