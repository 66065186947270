import React from 'react';
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { ApiDefinitionWidget, apiDocsConfigRef, defaultDefinitionWidgets } from '@backstage/plugin-api-docs';
import { ApiEntity } from '@backstage/catalog-model';
import { GraphiQLWidget } from '@internal/plugin-graphiql-widget';
import { Postman2OpenAPIWidget } from '@internal/plugin-postman-to-openapi';
import { OpenAPIWidget } from '@internal/plugin-openapi-widget';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      // load the default widgets
      const definitionWidgets = defaultDefinitionWidgets();

      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          // custom rendering for postman
          if (apiEntity.spec.type === 'postmancollection') {
            return {
              type: 'postmancollection',
              title: 'Postman Collection To OpenAPI',
              component: definition => <Postman2OpenAPIWidget definition={definition} metadata={apiEntity.metadata} />,
            } as ApiDefinitionWidget;
          } else if (apiEntity.spec.type === 'openapi') {
            return {
              type: 'openapi',
              title: 'OpenAPI',
              component: definition => <OpenAPIWidget definition={definition} metadata={apiEntity.metadata}/>,
            } as ApiDefinitionWidget;
          } else if (apiEntity.spec.type === 'graphql') {
            return {
              type: 'graphql',
              title: 'GraphQL',
              component: definition => <GraphiQLWidget definition={definition} metadata={apiEntity.metadata} />,
            } as ApiDefinitionWidget;
          }

          // fallback to the defaults
          return definitionWidgets.find(d => d.type === apiEntity.spec.type);
        }
      };
    }
  }),
  ScmAuth.createDefaultApiFactory(),
];
