import { createComponentExtension, createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const openapiWidgetPlugin = createPlugin({
  id: 'openapi-widget',
  routes: {
    root: rootRouteRef,
  },
});

export const OpenAPIWidget = openapiWidgetPlugin.provide(
  createComponentExtension({
    name: 'OpenapiWidget',
    component: {
      lazy: () =>
        import('./components/OpenApiComponent').then(m => m.OpenAPIWidget)
    }
  }),
);
